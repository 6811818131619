import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Pagination from "../../components/ui/pagination/Pagination";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import TableUnit from "../../components/units/DataPoint/Payment/TableUnit";
import * as C from "../../components/ui/common/CommonStyles";
import Search from "../../components/ui/search/Search";
import { AxiosCommonError } from "../../shared/types";
import { handleError } from "../../components/ui/err/handleError";

const DataPointPayment = () => {
  const navigate = useNavigate();

  // 현재 페이지 상태값 및 시작 & 엑티브 페이지 상태값 저장
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startPage, setStartPage] = useState<number>(1);
  const [active, setActive] = useState<string>("1");

  // 클라이언트단 url parameter 설정
  const [searchParams, setSearchParams] = useSearchParams("");
  const searchKeywordUrl = searchParams.get("keyword") || "";
  const searchTypeUrl = searchParams.get("searchType") || "all";
  // 검색 버튼 클릭시, input창 값 가져오기
  const [searchInput, setSearchInput] = useState<string>("");
  // select 창 값 가져오기
  const [searchType, setSearchType] = useState<string>("all");

  // 상태 변환시 input, select창 초기화
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  // 검색, 초기화시 Pagination 컴포넌트 상태 초기화
  useEffect(() => {
    setCurrentPage(1);
    setStartPage(1);
    setActive("1");

    // 사이드바 클릭시 input & select 창 초기화 안되던 버그 핸들링 💡💡💡💡💡
    if (searchKeywordUrl === "" && searchTypeUrl === "all") {
      inputRef.current!.value = "";
      selectRef.current!.value = "all";
    }

    // 새로고침시 setSearchParams 로 할당해준 url 쿼리 스트링 초기화 안되던 버그 핸들링 🐛🐛🐛🐛🐛
    if (searchKeywordUrl !== "") {
      inputRef.current!.value = searchKeywordUrl;
      selectRef.current!.value = searchTypeUrl;
      setSearchInput(searchKeywordUrl);
      setSearchType(searchTypeUrl);
    }
  }, [searchParams, searchKeywordUrl, searchTypeUrl]);

  // 데이터 포인트 결제 리스트 조회 쿼리
  const { data: getDataPointOrderListQuery } = useQuery(
    [
      "loadDataPointOrderListQuery",
      currentPage,
      searchTypeUrl,
      searchKeywordUrl,
    ],
    () =>
      axios.get(
        `https://relaypoint.edgecross.ai:8080/admin/ordersheet/list?searchType=${searchTypeUrl}&keyword=${searchKeywordUrl}&limit=10&page=${currentPage}&statuscd=1`
      ),
    {
      refetchOnWindowFocus: false,
      onError: (error: AxiosError<AxiosCommonError>) => {
        handleError(error);
      },
    }
  );

  // 페이지네이션 처리를 위한 토탈값
  const total: number = getDataPointOrderListQuery?.data.count;

  const options = [
    { value: "all", label: "All" },
    { value: "userId", label: "유저 아이디" },
    { value: "deviceId", label: "디바이스 번호" },
  ];

  const onChangeSearchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchType(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchParams(
        `searchType=${searchType}&keyword=${e.currentTarget.value}`
      );
    }
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const onClickSearch = () => {
    setSearchParams(`searchType=${searchType}&keyword=${searchInput}`);
  };

  return (
    <div>
      <C.TitleBox>
        <div className="title">결제내역 리스트</div>
      </C.TitleBox>
      <C.ListBox>
        <C.ControlWrap>
          <Search
            total={total}
            options={options}
            selectRef={selectRef}
            inputRef={inputRef}
            onChangeSearchType={onChangeSearchType}
            onKeyDown={onKeyDown}
            onChangeInput={onChangeInput}
            onClickSearch={onClickSearch}
          />
        </C.ControlWrap>
        <C.TableWrap>
          <TableUnit data={getDataPointOrderListQuery} />
          {total > 0 ? (
            <Pagination
              total={total}
              size={10}
              setCurrentPage={setCurrentPage}
              startPage={startPage}
              setStartPage={setStartPage}
              active={active}
              setActive={setActive}
            />
          ) : null}
        </C.TableWrap>
      </C.ListBox>
    </div>
  );
};

export default DataPointPayment;
