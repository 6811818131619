import React, { useRef, useState } from "react";
import styled from "styled-components";
import { BsPencilSquare } from "react-icons/bs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apis from "../../../../shared/apis";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import { AxiosCommonError } from "../../../../shared/types";
import { handleError } from "../../../ui/err/handleError";
import Table from "../../../ui/table/Table";

const Select = styled.select`
  width: 70%;
  padding: 5px;
  text-align: center;
  border: 1px solid #e1e1e1;
`;

interface IPropsTableUnit {
  data: any;
  checkBoxArr: number[];
  setCheckBoxArr: React.Dispatch<React.SetStateAction<number[]>>;
  resetCheckBoxArr: () => void;
}

export default function TableUnit(props: IPropsTableUnit) {
  const { data, checkBoxArr, setCheckBoxArr, resetCheckBoxArr } = props;
  const queryClient = useQueryClient();
  // 모드링크 출고등록 input 관리
  const typeRef = useRef<HTMLInputElement>(null);
  const serialNumberRef = useRef<HTMLInputElement>(null);
  const macAddressRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  // 모드링크 출고목록 정보 수정
  const [editId, setEditId] = useState<number>(-1);
  // 실출고 : 1, 테스트 : 0
  const [stage, setStage] = useState<number>(1);

  const onClickSetEdit = (id: number) => {
    return () => {
      setEditId(id);
    };
  };

  const resetEdit = () => {
    setEditId(-1);
  };

  // 체크박스 개별 선택
  const clickCheckBox = (e: React.MouseEvent<HTMLInputElement>) => {
    checkBoxArr.includes(Number(e.currentTarget.id))
      ? setCheckBoxArr(
          checkBoxArr.filter((v) => v !== Number(e.currentTarget.id))
        )
      : setCheckBoxArr([...checkBoxArr, Number(e.currentTarget.id)]);
  };

  // 체크박스 전체 선택
  const clickCheckBoxAll = (e: React.MouseEvent<HTMLInputElement>) => {
    // 체크박스 전체 선택/취소를 위한 id 값 받기
    const idList = data?.data.content.map((v: any) => v.id) || [];
    e.currentTarget.checked ? setCheckBoxArr([...idList]) : resetCheckBoxArr();
  };

  // 모드링크 출고목록 수정 쿼리
  const { mutate: editMutate } = useMutation(
    ({
      id,
      data,
    }: {
      id: number;
      data: {
        deviceType: string;
        serialNumber: string;
        macAddress: string;
        company: string;
        stage: number;
      };
    }) => apis.editModLink(id, data),
    {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "수정이 완료되었습니다.",
        });
        resetEdit();
        queryClient.invalidateQueries({ queryKey: ["loadModLinkQuery"] });
      },
      onError: (error: AxiosError<AxiosCommonError>) => {
        handleError(error);
      },
    }
  );
  // 모드링크 출고목록 수정
  const onClickEditModlink = async (id: number) => {
    const data = {
      deviceType: typeRef.current?.value || "",
      serialNumber: serialNumberRef.current?.value || "",
      macAddress: macAddressRef.current?.value || "",
      company: companyRef.current?.value || "",
      stage,
    };
    editMutate({ id, data });
  };

  return (
    <Table>
      <Table.THead>
        <Table.TRow>
          <Table.TColumn.CheckboxColumn
            isChecked={data?.data.content.length === checkBoxArr.length}
            handleCheckBox={clickCheckBoxAll}
            size="sm"
          />
          <Table.TColumn.DefaultColumn size="sm" />
          <Table.TColumn.DefaultColumn size="md">
            날짜
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            종류
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            S/N
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            MAC
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="lg">
            출고기업
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            출고종류
          </Table.TColumn.DefaultColumn>
          <Table.TColumn.DefaultColumn size="md">
            출고담당자
          </Table.TColumn.DefaultColumn>
        </Table.TRow>
      </Table.THead>
      <Table.TBody>
        {data?.data.content.map((v: any) => (
          <Table.TRow key={v.id} hoverActive>
            {editId === v.id ? (
              <Table.TCell.DefaultCell
                icon={<BsPencilSquare />}
                onClick={() => onClickEditModlink(v.id)}
              />
            ) : (
              <Table.TCell.CheckboxCell
                inputId={v.id}
                isChecked={checkBoxArr.includes(v.id)}
                handleCheckBox={clickCheckBox}
              />
            )}
            <Table.TCell.DefaultCell>{v.id}</Table.TCell.DefaultCell>
            <Table.TCell.DefaultCell>
              {v.createdDt.substr(2, 8)}
            </Table.TCell.DefaultCell>
            <Table.TCell.DefaultCell onClick={onClickSetEdit(v.id)} pointer>
              {editId === v.id ? (
                <input type="text" defaultValue={v.deviceType} ref={typeRef} />
              ) : (
                v.deviceType
              )}
            </Table.TCell.DefaultCell>
            <Table.TCell.DefaultCell onClick={onClickSetEdit(v.id)} pointer>
              {editId === v.id ? (
                <input
                  type="text"
                  defaultValue={v.serialNumber}
                  ref={serialNumberRef}
                />
              ) : (
                v.serialNumber
              )}
            </Table.TCell.DefaultCell>
            <Table.TCell.DefaultCell onClick={onClickSetEdit(v.id)} pointer>
              {editId === v.id ? (
                <input
                  type="text"
                  defaultValue={v.macAddress}
                  ref={macAddressRef}
                />
              ) : (
                v.macAddress
              )}
            </Table.TCell.DefaultCell>
            <Table.TCell.DefaultCell
              onClick={onClickSetEdit(v.id)}
              pointer={true}
            >
              {editId === v.id ? (
                <input type="text" defaultValue={v.company} ref={companyRef} />
              ) : (
                v.company
              )}
            </Table.TCell.DefaultCell>
            <Table.TCell.DefaultCell
              onClick={() => {
                setEditId(v.id);
                setStage(v.stage);
              }}
              pointer={true}
            >
              {editId === v.id ? (
                <Select
                  ref={selectRef}
                  defaultValue={v.stage}
                  onChange={(e) => {
                    setStage(Number(e.target.value));
                  }}
                >
                  <option value="1">실출고</option>
                  <option value="0">테스트</option>
                </Select>
              ) : v.stage === 1 ? (
                "실출고"
              ) : (
                "테스트"
              )}
            </Table.TCell.DefaultCell>
            <Table.TCell.DefaultCell>{v.adminEmail}</Table.TCell.DefaultCell>
          </Table.TRow>
        ))}
      </Table.TBody>
    </Table>
  );
}
