import React from "react";
import { Table as StyleTable } from "./unit/styles";
import THead from "./unit/Thead";
import TBody from "./unit/TBody";
import TRow from "./unit/TRow";
import TColumn from "./unit/TColumn";
import TCell from "./unit/TCell";

interface IPropsTable extends React.HTMLAttributes<HTMLTableElement> {
  children: React.ReactNode;
}

export default function Table({ children, ...props }: IPropsTable) {
  return <StyleTable {...props}>{children}</StyleTable>;
}

Table.THead = THead;
Table.TBody = TBody;
Table.TRow = TRow;
Table.TColumn = TColumn;
Table.TCell = TCell;
